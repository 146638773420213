var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"create pb-4",staticStyle:{"width":"100%"}},[_c('CCardGroup',{staticClass:"has-loading-overlay"},[_c('CCard',{staticClass:"p-3"},[_c('div',{staticStyle:{"background-color":"#f5f5f0"}},[_c('CRow',[_c('div',{staticClass:"d-flex header-content"},[_c('div',{staticClass:"title-header p-3",staticStyle:{"min-width":"150px"}},[_c('CRow',{staticClass:"justify-content-center"},[(_vm.jobReference)?_c('img',{staticClass:"rounded-circle ratio-1",staticStyle:{"width":"60%"},attrs:{"src":_vm.fileSource.logo,"alt":"logo"},on:{"error":function($event){$event.target.src = '/img/hospital.png';
                    $event.target.style = 'width:60%';}}}):_vm._e(),(!_vm.isCandidateOrPublicBoard && _vm.isParentReBranding)?_c('span',{staticClass:"text-danger parent-info",staticStyle:{"color":"danger"}},[_c('em',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                      content: 'Parent Branding Enabled',
                      appendToBody: true,
                    }),expression:"{\n                      content: 'Parent Branding Enabled',\n                      appendToBody: true,\n                    }"}],staticClass:"dot"})]):_vm._e()]),_c('div',{staticClass:"my-3"},[_c('h5',{staticClass:"text-primary text-center font-weight-bold"},[_vm._v(" "+_vm._s(_vm.organisation)+" ")]),_c('h6',{staticClass:"label lblspan text-primary text-center"},[_c('em',{staticClass:"fas fa-map-marker-alt"}),_vm._v("  "),_c('span',{staticStyle:{"color":"#00000099"}},[_vm._v(_vm._s(_vm.location)+_vm._s(_vm.displayLocation ? ' | ' + _vm.displayLocation : ''))])])])],1),_c('div',{staticClass:"p-4",staticStyle:{"min-width":"150px"}},[_c('div',{staticClass:"title row"},[_c('h4',{staticClass:"text-left text-primary"},[_vm._v(_vm._s(_vm.title))])]),_c('CRow',{staticClass:"d-flex justify-content-between flex-row"},[_c('CCol',{staticClass:"property"},[_c('p',{staticClass:"text-primary"},[_vm._v("Candidate Type")]),_c('p',{staticClass:"value"},[_vm._v(_vm._s(_vm.candidateType))]),(_vm.isNurse && _vm.jobDetails.location.country_id == 182)?_c('div',[_c('p',{staticClass:"value"},[_vm._v(_vm._s(_vm.nhsBand))])]):_vm._e()]),_c('CCol',{staticClass:"property"},[_c('p',{staticClass:"text-primary"},[_vm._v("Job Reference")]),_c('p',{staticClass:"value"},[_vm._v(_vm._s(_vm.displayJobReference))])]),_c('CCol',{staticClass:"property"},[_c('p',{staticClass:"text-primary"},[_vm._v("Date Posted")]),_c('p',{staticClass:"value"},[_vm._v(_vm._s(_vm.date_created))])]),_c('CCol',{staticClass:"property"},[_c('p',{staticClass:"text-primary"},[_vm._v("Candidate Level")]),(_vm.isWrapped(_vm.candidateLevel))?_c('p',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                      content: _vm.candidateLevel,
                    }),expression:"{\n                      content: candidateLevel,\n                    }"}],staticClass:"value"},[_vm._v(" "+_vm._s(_vm.candidateLevel.substring(0, 50))+"... ")]):_c('p',{staticClass:"value"},[_vm._v(_vm._s(_vm.candidateLevel))])]),_c('CCol',{staticClass:"property"},[_c('p',{staticClass:"text-primary"},[_vm._v("Contract")]),_c('p',{staticClass:"value"},[_vm._v(_vm._s(_vm.contract))])]),_c('CCol',{staticClass:"property"},[_c('p',{staticClass:"text-primary"},[_vm._v("Expires On")]),_c('p',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.expiryDate)+" | "),_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(_vm.expiryDays))])])])],1)],1)])])],1),_c('div',[(_vm.jobReference)?_c('img',{staticStyle:{"width":"100% !important","max-height":"250px"},attrs:{"src":_vm.fileSource.image,"alt":"Job Image"},on:{"error":function($event){$event.target.src = '/img/job-image.jpg'}}}):_vm._e()]),_c('CCardBody',{staticClass:"p-1 mt-3 overflow-auto",domProps:{"innerHTML":_vm._s(_vm.jobDescription)}}),_vm._l((_vm.jobDocuments),function(item,index){return _c('div',{key:index,staticClass:"pl-1 pr-3"},[_vm._v(" For Additional Information "),_c('a',{staticClass:"cursor-pointer text-primary",on:{"click":function($event){return _vm.fileDownload(item)}}},[_vm._v(" "+_vm._s(item.library_name || "Click here")+" ")])])})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }