<template>
  <div class="job_approval_comments">
    <CModal color="primary" :show.sync="isShowModal" :closeOnBackdrop="false" size="lg">
      <template #header>
        <h5>Job Approval Comments</h5>
        <button aria-label="Close" class="close" @click="popupModalCallBack(false)">x</button>
      </template>
      <div class="row d-flex justify-content-center">
        <div class="col-md-11" style="max-height: 400px; overflow: auto;">
          <div class="card p-3" v-for="(data, index) in audits" :key="index">
            <div class="d-flex justify-content-between align-items-center">
              <div class="align-items-center">
                <p class="font-weight-bold mb-1">
                  <span
                    :style="data.sub_status_id == 72 ? 'color: blue' : 'color: crimson'"
                  >{{ getUserName(data)}}</span>
                  <span
                    class="ml-2"
                    style="font-weight: 600"
                  >({{data.status}}{{data.sub_status ? ` / ${data.sub_status}` : ``}})</span>
                </p>
                <span class="ml-3" style="font-weight: 500">{{data.comments}}</span>
              </div>
              <div style="min-width: 20%">
                <small style="font-weight: 600">{{formatDate(data.initiated_date)}}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <CButton color="primary" @click="popupModalCallBack()">Ok</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import m from "moment";
import Modal from "@/components/reusable/Modal";

export default {
  name: "JobApprovalComments",
  extends: Modal,
  props: ["popupModalCallBack", "approval_audits"],
  watch: {
    isShowPopup(newVal) {
      this.isShow = newVal;
    },
  },
  computed: {
    isShowModal: {
      get() {
        return this.isShow;
      },
      set(isShowPopup) {
        this.isShow = isShowPopup;
      },
    },
    audits() {
      if (this.approval_audits?.length) {
        return this.approval_audits?.sort(
          (a, b) => new Date(b.initiated_date) - new Date(a.initiated_date)
        );
      }
      return [];
    },
  },
  methods: {
    getUserName(data) {
      return `${data.initiated_by_user?.first_name} ${data.initiated_by_user.surname}`;
    },
    formatTime(date) {
      date = new Date(date + "Z");
      return m(date).format("hh:mm A");
    },
    formatDate(date) {
      date = new Date(date + "Z");
      return m(date).format("MMMM DD, YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  border: none;
  box-shadow: 5px 6px 6px 2px #e9ecef;
  border-radius: 4px;
}
@media (max-width: 520px) {
  .job_approval_comments {
    font-size: 12px;
  }
}
</style>